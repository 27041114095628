const preferences = {
    analytics: true,
    functional: true,
    targeting: true,
};

import { setPreferences } from '@js/core/cookies/cookiePreferences.js';

export default {
    data() {
        return {
            panelVisible: false,
        };
    },
    computed: {
    },
    methods: {
        openPanel() {
            this.panelVisible = true;
        },
        acceptCookies() {
            setPreferences(preferences);
            this.closePanel();
        },
        closePanel() {
            this.panelVisible = false;
            this.visible = false;
        },
    },
};
